/** @jsx jsx */
import { jsx, Image, Flex} from 'theme-ui';
import ConditionalLayout from "../components/ConditionalLayout"
import SEO from "../components/seo"

import machineLaser from "assets/images/machineLaser.png"
import { Divider } from '@theme-ui/components';

const modalStyle = {
    background: `rgba(0,0,0,0.03)`,
}

const LaserModal = () => (
  <ConditionalLayout style={modalStyle}>
    <SEO title="Page two" />
    <Flex sx={{alignItems: 'end'}}>
        <h1 sx={styles.modalHead}>光脱毛・機械のご紹介</h1>
        <Divider sx={{marginRight: '51px', display: ['none', 'block']}}/>
    </Flex>
    <h2 sx={styles.modalSubHead}>光脱毛で使用するハイブリッド脱毛とは</h2>

    <div sx={styles.modalBodyWrap}>
        <div sx={styles.modalBody}>
            <p>
                ハイブリッド脱毛とは。お肌を最大−10℃に冷却させながら、バルジ領域と毛根部に特殊な光を照射することにより、お肌にやさしく安全に毛を再生しづらくさせる最先端の方法です。
            </p>
            <p>
                最先端の研究では、皮脂腺の下部にある「バルジ」と呼ばれる部分から発毛因子を作り出すことがわかってきました。ハイブリッド脱毛は、バルジ領域と毛根部を同時に特殊な光を照射することにより、発毛因子の細胞が減退し、毛を再生しづらくさせます。更に、発行面を最大−10℃に冷却するので、安全に、痛みも少なく、お肌にやさしく、短時間で原毛効果が期待できます。
            </p>
            <p>
            バルジ領域と毛根部の両方をターゲットにしているので、今までの脱毛より毛周期に左右されづらい脱毛が可能です。
            </p>
            <Image src={machineLaser} sx={styles.modalBodyImage}/>
        </div>
    </div>
  </ConditionalLayout>
)

export default LaserModal

const styles = {
    modalHead: {
        mb: ['10px', '16px'],
        mt: ['40px', '75px'],
        pl: ['15px', '51px'],
        backgroundImage: `-webkit-linear-gradient(left, #416585 0%, #FF8B81 25%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontWeight: 400,
        fontSize: [22, 32],
    },
    modalSubHead: {
        fontSize: [16, 25],
        pl: ['15px', '51px'],
        mb: '10px',
        mt: 0,
    },
    modalBodyWrap: {
        height: ['auto', '400px'],
        background: 'linear-gradient(99.22deg, rgba(217, 237, 255, 0.5) -0.11%, rgba(255, 232, 230, 0.5) 68.8%)',
        fontSize: 14,
        width: '100%',
        // marginLeft: '-50px',
    },
    modalBody: {
        maxWidth: ['auto', '570px'],
        position: 'relative',
        padding: ['15px 0 270px 15px', '41px 0px 128px 51px'],
        // pb: ['270px', 0],
    },
    modalBodyImage: {
        position: ['absolute', 'absolute', 'absolute'],
        width: ['70%', 'unset'],
        right: ['0', '-432px'],
        top: ['unset', '-130px'],
    }
}